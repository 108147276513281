import { FC } from 'react';
import IconProps from './IconProps';

const AuditIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.275 14.8114C10.65 14.1821 9.63212 14.1821 9.00713 14.8114V14.8114C8.38819 15.4346 8.38819 16.4405 9.00713 17.0637L12.6697 20.7515C12.9683 21.0525 13.3733 21.2218 13.7959 21.2222C14.0095 21.2186 14.2202 21.1717 14.4155 21.0844C14.6108 20.9971 14.7867 20.8712 14.9327 20.7141L22.3747 13.3224C23.0167 12.6846 23.0031 11.6419 22.3445 11.0212V11.0212C21.7179 10.4306 20.7364 10.4413 20.1229 11.0455L13.7587 17.3122L11.275 14.8114Z"
          fill="#5A5766"
        />
        <path
          d="M30.5425 15.6719C30.5453 12.3026 29.4047 9.03202 27.3072 6.39521C25.2097 3.75839 22.2795 1.9114 18.9958 1.15634C15.7122 0.401279 12.2696 0.78285 9.23098 2.23864C6.1924 3.69442 3.73777 6.13825 2.2686 9.17038C0.79942 12.2025 0.402667 15.6435 1.14323 18.9304C1.8838 22.2173 3.71785 25.1556 6.34539 27.2647C8.97292 29.3738 12.2384 30.5289 15.6077 30.5409C18.977 30.553 22.2507 29.4213 24.8932 27.331L32.6946 35.1324C33.3647 35.8026 34.4513 35.8026 35.1214 35.1324V35.1324C35.7916 34.4623 35.7916 33.3757 35.1214 32.7056L27.32 24.9042C29.4087 22.2805 30.5448 19.0254 30.5425 15.6719ZM15.6609 27.1193C13.3968 27.1193 11.1836 26.4479 9.30107 25.19C7.41856 23.9322 5.95132 22.1443 5.0849 20.0526C4.21847 17.9609 3.99178 15.6592 4.43348 13.4386C4.87518 11.218 5.96543 9.17831 7.56638 7.57737C9.16732 5.97642 11.207 4.88616 13.4276 4.44447C15.6482 4.00277 17.9499 4.22946 20.0416 5.09589C22.1333 5.96231 23.9212 7.42955 25.179 9.31206C26.4369 11.1946 27.1083 13.4078 27.1083 15.6719C27.1083 18.7079 25.9022 21.6196 23.7554 23.7664C21.6086 25.9132 18.6969 27.1193 15.6609 27.1193Z"
          fill="url(#paint0_linear_11092_153)"
        />
        <defs>
          <linearGradient id="paint0_linear_11092_153" x1="0.779297" y1="11.6383" x2="36.3387" y2="26.8339" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  return (
    <svg {...attr} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.36479 9.48687C6.9898 9.1093 6.37907 9.1093 6.00408 9.48687C5.63272 9.8608 5.63272 10.4643 6.00408 10.8383L8.20161 13.0509C8.38076 13.2315 8.62381 13.3331 8.87732 13.3333C9.00551 13.3312 9.13195 13.3031 9.24913 13.2507C9.3663 13.1983 9.47183 13.1227 9.55941 13.0285L14.0246 8.59345C14.4098 8.2108 14.4017 7.58515 14.0065 7.21273C13.6306 6.85837 13.0417 6.86481 12.6735 7.2273L8.85501 10.9874L7.36479 9.48687Z"
        fill={accentColor}
      />
      <path
        d="M18.9243 10.0031C18.926 7.98151 18.2416 6.01918 16.9831 4.43709C15.7246 2.855 13.9665 1.74681 11.9963 1.29377C10.0262 0.840734 7.96057 1.06968 6.13742 1.94315C4.31427 2.81662 2.84149 4.28292 1.95999 6.10219C1.07848 7.92147 0.840428 9.98604 1.28477 11.9582C1.72911 13.9303 2.82954 15.6933 4.40606 16.9588C5.98258 18.2243 7.94187 18.9173 9.96345 18.9245C11.985 18.9317 13.9492 18.2527 15.5347 16.9986L20.2156 21.6794C20.6177 22.0815 21.2696 22.0815 21.6717 21.6794C22.0738 21.2773 22.0738 20.6254 21.6717 20.2233L16.9909 15.5425C18.244 13.9682 18.9257 12.0152 18.9243 10.0031ZM9.99536 16.8715C8.63692 16.8715 7.30898 16.4687 6.17947 15.714C5.04997 14.9593 4.16962 13.8866 3.64977 12.6315C3.12991 11.3765 2.99389 9.99547 3.25891 8.66313C3.52393 7.33079 4.17809 6.10695 5.13865 5.14639C6.09922 4.18582 7.32306 3.53167 8.6554 3.26665C9.98774 3.00163 11.3688 3.13764 12.6238 3.6575C13.8788 4.17735 14.9515 5.0577 15.7062 6.1872C16.461 7.31671 16.8638 8.64465 16.8638 10.0031C16.8638 11.8247 16.1402 13.5717 14.8521 14.8598C13.564 16.1479 11.817 16.8715 9.99536 16.8715Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AuditIcon;
